import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";

import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 00",
  week: "Semana 0",
  day: "13",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-13",
  path: "/cronologia/semana-0#dia-13-mar",
};
const Day13M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />

        <ModDataEu fecha={frontmatter.date} />
      </ContentLeft>

      <ContentRight>
        <ModText text1="En España se han registrado 1.259 más que el día anterior y 118 fallecidos nuevos,lo que representa un icremento del 40.4% intradía. El total de personas en UCI asciende a 272." />
        <ModText>
          Las comunidades autónomas con una mayor incidencia acumulada en los
          últimos días son Madrid (1.990 casos y 81 fallecidos), País Vasco (417
          casos y 14 fallecidos), Cataluña (316 casos y 4 fallecidos), La Rioja
          (243 casos y 2 fallecidos) y Andalucía (219 casos).
        </ModText>

        <ModText>
          <strong>Canarias, Cataluña y País Vasco</strong> se suman hoy a las
          medidas implementadas en Madrid y La Rioja,{" "}
          <strong>cerrando todos sus centros educativos</strong>.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day13M;
